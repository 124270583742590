import * as React from "react"
import { Link, navigate } from "gatsby"

import styled from '@emotion/styled'
import { StaticImage } from "gatsby-plugin-image"
import { Typography } from "@mui/material"
import { Color } from '../../util/Color'

import MobileHeader from "./MobileHeader"

const TopHeader = () => {

  const [view, setView] = React.useState('PC')

  React.useEffect(() => {
    const w = window.innerWidth
    setView((() => {
      if (1440 < w) return "PC"
      else if (960 < w) return "TABLET"
      else return "MOBILE"
    })())
  })


  if (view === 'MOBILE') {
    return <MobileHeader />
  }

  return (
  <Root>
    <LogoWrapper onClick={() => navigate('/')}>
      <StaticImage src="./header-logo-white.svg" alt="logo-white"/>
    </LogoWrapper>
    <LinkWrapper>
      <LinkArea to="/about" view={view}>
        <STypography variant="h5" view={view}>
          会社について
        </STypography>
      </LinkArea>
      <LinkArea to="/service" view={view}>
        <STypography variant="h5" view={view}>事業内容</STypography>
      </LinkArea>
      <LinkArea to="https://www.juggle.jp/" view={view}>
        <STypography variant="h5" view={view}>JUGGLE</STypography>
      </LinkArea>
      <LinkArea to="https://machimeguri.app/" view={view}>
          <STypography variant="h5" view={view}>まちめぐり</STypography>
        </LinkArea>
      <LinkArea to="/news" view={view}>
        <STypography variant="h5" view={view}>お知らせ</STypography>
      </LinkArea>
      <LinkArea to="https://qiita.com/organizations/opening-line" view={view}>
        <STypography variant="h5" view={view}>BLOG</STypography>
      </LinkArea>
        <LinkArea to="/recruitment" view={view}>
          <STypography variant="h5" view={view}>採用</STypography>
        </LinkArea>
      <CircleLinkArea to="/contact" view={view}>
        <STypography variant="h5" view={view}>お問い合わせ</STypography>
      </CircleLinkArea>
    </LinkWrapper>
  </Root>
)
}

export default TopHeader

const Root = styled('div')({
  height: '100px',
  width: '100vw',
  position: 'fixed',
  top: '0px',
  zIndex: '999',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})

const LogoWrapper = styled('div')(({view}) => ({
  height: '100%',
  width: view === 'PC' ? '292px' : 'calc(9.375rem + ((1vw - 7.4px) * 14.5714))',
  padding: '30px 0px',
  paddingLeft: view === 'PC' ? '40px' : 'calc(0.75rem + ((1vw - 7.4px) * 4))',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
}))

const LinkWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  color: Color.white
})

const LinkArea = styled(Link)(({view}) => ({
  marginRight: view === 'PC' ? '80px' : 'calc(2rem + ((1vw - 7.4px) * 1.1429))',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  textDecoration: 'none',
}))

const CircleLinkArea = styled(Link)(({view}) => ({
  padding: `0px ${view === 'PC' ? '60px' : 'calc(1rem + ((1vw - 7.4px) * 2))'}`,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  textDecoration: 'none',
  '> *': {
    border: `${Color.white} 1px solid`,
    borderRadius: '200px',
    padding: '10px 23px'
  }
}))

const STypography = styled(Typography)(({view}) => ({
  fontFamily: 'Noto Sans JP',
  fontWeight: 400,
  fontSize: view === 'PC' ? '20px' : 'calc(0.875rem + ((1vw - 7.4px) * 0.2857)) '
}))